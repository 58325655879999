<template>
  <div class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->
    <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
      <!--begin::Card-->
      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2 class="mb-0">Frontend Developer</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Permissions-->
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 3 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Vuejs, Typescript ile
              tecrübesi olan
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Tercihen Angular ve
              React ile tecrübesi olan
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Ekip çalışmasını
              seven ve Agile metodunu benimseyen
            </div>

            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>7 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer pt-0">
          <button
            type="button"
            class="btn btn-light btn-active-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_update_role"
          >
            Pozisyonu Düzenle
          </button>
        </div>
        <!--end::Card footer-->
      </div>
      <!--end::Card-->

      <!--begin::Modal-->

      <!--begin::Modal - Update role-->
      <div
        class="modal fade"
        id="kt_modal_update_role"
        tabindex="-1"
        aria-hidden="true"
      >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-750px">
          <!--begin::Modal content-->
          <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">Pozisyonu Güncelle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-roles-modal-action="close"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5 my-7">
              <!--begin::Form-->
              <form
                id="kt_modal_update_role_form"
                class="form fv-plugins-bootstrap5 fv-plugins-framework"
                action="#"
              >
                <!--begin::Scroll-->
                <div
                  class="d-flex flex-column scroll-y me-n7 pe-7"
                  id="kt_modal_update_role_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_update_role_header"
                  data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
                  data-kt-scroll-offset="300px"
                  style="max-height: 541px"
                >
                  <!--begin::Input group-->
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2">
                      <span class="required">Pozisyon Adı</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      class="form-control form-control-solid"
                      placeholder="Pozisyon adı girin"
                      name="role_name"
                      value="Frontend Developer"
                    />
                    <!--end::Input-->
                    <div
                      class="
                        fv-plugins-message-container
                        fv-plugins-message-container--enabled
                        invalid-feedback
                      "
                    ></div>
                  </div>
                  <!--end::Input group-->

                  <div class="fv-row mb-5">
                  <!--begin::Label-->
                  <label class="fs-5 fw-bold form-label mb-2"
                    >İlan Açıklaması</label
                  >
                  <!--end::Label-->


                  <vue-editor v-model="positionDesc" class="form-control form-control-solid" style="padding: 0px"></vue-editor>

                </div>

                  <!--begin::Permissions-->
                  <div class="fv-row">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2"
                      >Aranılan Özellikler</label
                    >
                    <!--end::Label-->

                    <!--begin::Table wrapper-->
                    <div class="table-responsive">
                      <!--begin::Table-->
                      <table
                        class="table align-middle table-row-dashed fs-6 gy-5"
                      >
                        <!--begin::Table body-->
                        <tbody class="text-gray-600 fw-semibold">
                          <!--begin::Table row-->
                          <tr>
                            <td class="text-gray-800">
                              Administrator Access

                              <span
                                class="ms-1"
                                data-bs-toggle="tooltip"
                                aria-label="Allows a full access to the system"
                                data-bs-original-title="Allows a full access to the system"
                                data-kt-initialized="1"
                              >
                                <i
                                  class="
                                    ki-duotone ki-information-5
                                    text-gray-500
                                    fs-6
                                  "
                                  ><span class="path1"></span
                                  ><span class="path2"></span
                                  ><span class="path3"></span></i
                              ></span>
                            </td>
                            <td>
                              <!--begin::Checkbox-->
                              <label
                                class="
                                  form-check
                                  form-check-sm
                                  form-check-custom
                                  form-check-solid
                                  me-9
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="kt_roles_select_all"
                                />
                                <span
                                  class="form-check-label"
                                  for="kt_roles_select_all"
                                >
                                  Select all
                                </span>
                              </label>
                              <!--end::Checkbox-->
                            </td>
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">User Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="user_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="user_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="user_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Content Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="content_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="content_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="content_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Financial Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="financial_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="financial_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="financial_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Reporting</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="reporting_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="reporting_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="reporting_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Payroll</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="payroll_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="payroll_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="payroll_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Disputes Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="disputes_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="disputes_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="disputes_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">API Controls</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="api_controls_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="api_controls_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="api_controls_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Database Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="database_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="database_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="database_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                          <!--begin::Table row-->
                          <tr>
                            <!--begin::Label-->
                            <td class="text-gray-800">Repository Management</td>
                            <!--end::Label-->

                            <!--begin::Input group-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-sm
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="repository_management_read"
                                  />
                                  <span class="form-check-label"> Read </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                    me-5 me-lg-20
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="repository_management_write"
                                  />
                                  <span class="form-check-label"> Write </span>
                                </label>
                                <!--end::Checkbox-->

                                <!--begin::Checkbox-->
                                <label
                                  class="
                                    form-check
                                    form-check-custom
                                    form-check-solid
                                  "
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    name="repository_management_create"
                                  />
                                  <span class="form-check-label"> Create </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Input group-->
                          </tr>
                          <!--end::Table row-->
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                    </div>
                    <!--end::Table wrapper-->
                  </div>
                  <!--end::Permissions-->
                </div>
                <!--end::Scroll-->

                <!--begin::Actions-->
                <div class="text-center pt-15">
                  <button
                    type="reset"
                    class="btn btn-light me-3"
                    data-kt-roles-modal-action="cancel"
                  >
                    Vazgeç
                  </button>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-kt-roles-modal-action="submit"
                  >
                    <span class="indicator-label"> Güncelle </span>
                    <span class="indicator-progress">
                     Lütfen bekleyiniz...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                </div>
                <!--end::Actions-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Modal body-->
          </div>
          <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
      </div>
      <!--end::Modal - Update role--><!--end::Modal-->
    </div>
    <!--end::Sidebar-->

    <!--begin::Content-->
    <div class="flex-lg-row-fluid ms-lg-10">
      <!--begin::Card-->
      <div class="card card-flush mb-6 mb-xl-9">
        <!--begin::Card header-->
        <div class="card-header pt-5">
          <!--begin::Card title-->
          <div class="card-title">
            <h2 class="d-flex align-items-center">
              Mülakat Gönderilen Adaylar<span class="text-gray-600 fs-6 ms-1">(14)</span>
            </h2>
          </div>
          <!--end::Card title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Search-->
            <div
              class="d-flex align-items-center position-relative my-1"
              data-kt-view-roles-table-toolbar="base"
            >
              <i class="ki-duotone ki-magnifier fs-1 position-absolute ms-6"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <input
                type="text"
                data-kt-roles-table-filter="search"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Aday ara"
              />
            </div>
            <!--end::Search-->

            <!--begin::Group actions-->
            <div
              class="d-flex justify-content-end align-items-center d-none"
              data-kt-view-roles-table-toolbar="selected"
            >
              <div class="fw-bold me-5">
                <span
                  class="me-2"
                  data-kt-view-roles-table-select="selected_count"
                ></span>
                Selected
              </div>

              <button
                type="button"
                class="btn btn-danger"
                data-kt-view-roles-table-select="delete_selected"
              >
                Delete Selected
              </button>
            </div>
            <!--end::Group actions-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Table-->
          <div
            id="kt_roles_view_table_wrapper"
            class="dt-container dt-bootstrap5 dt-empty-footer"
          >
            <div id="" class="table-responsive">
              <table
                class="
                  table
                  align-middle
                  table-row-dashed
                  fs-6
                  gy-5
                  mb-0
                  dataTable
                "
                id="kt_roles_view_table"
                style="width: 100%"
              >
                <colgroup>
                  <col data-dt-column="0" style="width: 36.3906px" />
                  <col data-dt-column="1" style="width: 320.281px" />
                  <col data-dt-column="2" style="width: 355.578px" />
                  <col data-dt-column="3" style="width: 260.875px" />
                  <col data-dt-column="4" style="width: 207.875px" />
                  <col data-dt-column="5" style="width: 120.875px" />
                  <col data-dt-column="6" style="width: 80.875px" />
                </colgroup>
                <thead>
                  <tr
                    class="
                      text-start text-muted
                      fw-bold
                      fs-7
                      text-uppercase
                      gs-0
                    "
                  >
                    <th
                      class="w-10px pe-2 dt-orderable-none"
                      data-dt-column="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="
                    "
                    >
                      <span class="dt-column-title">
                        <div
                          class="
                            form-check
                            form-check-sm
                            form-check-custom
                            form-check-solid
                            me-3
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            data-kt-check="true"
                            data-kt-check-target="#kt_roles_view_table .form-check-input"
                            value="1"
                          />
                        </div> </span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th
                      class="min-w-150px dt-orderable-asc dt-orderable-desc"
                      data-dt-column="2"
                      rowspan="1"
                      colspan="1"
                      aria-label="User: Activate to sort"
                      tabindex="0"
                    >
                      <span class="dt-column-title" role="button">Aday Bilgileri</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th
                      class="min-w-125px dt-orderable-asc dt-orderable-desc"
                      data-dt-column="3"
                      rowspan="1"
                      colspan="1"
                      aria-label="Joined Date: Activate to sort"
                      tabindex="0"
                    >
                      <span class="dt-column-title" role="button"
                        >Mülakat Başlığı</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th
                      class="min-w-125px dt-orderable-asc dt-orderable-desc"
                      data-dt-column="4"
                      rowspan="1"
                      colspan="1"
                      aria-label="Joined Date: Activate to sort"
                      tabindex="0"
                    >
                      <span class="dt-column-title" role="button"
                        >Son İşlem Tarihi</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th
                      class="min-w-125px dt-orderable-asc dt-orderable-desc"
                      data-dt-column="5"
                      rowspan="1"
                      colspan="1"
                      aria-label="Joined Date: Activate to sort"
                      tabindex="0"
                    >
                      <span class="dt-column-title" role="button"
                        >Durum</span
                      ><span class="dt-column-order"></span>
                    </th>
                    <th
                      class="text-end min-w-100px dt-orderable-none"
                      data-dt-column="6"
                      rowspan="1"
                      colspan="1"
                      aria-label="İşlem"
                    >
                      <span class="dt-column-title">İşlem</span
                      ><span class="dt-column-order"></span>
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                      <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                        >
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-danger
                              text-danger
                            "
                          >
                            B
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <div class="d-flex flex-column">
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Buse Çallı</a
                        >
                        <span>buse@gmail.com</span>
                      </div>
                      <!--begin::User details-->
                    </td>
                    <td>
                     Hybrid Çalışacak Frontend Developer
                    </td>
                    <td data-order="2024-09-22T20:43:00+03:00">
                      22 Mayıs 2024, 8:43
                    </td>
                    <td>
                      <span
                      class="badge badge-light-success"
                          >{{ $t("common.continues") }}</span
                        >
                    </td>
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        İşlemler
                        <i class="ki-duotone ki-down fs-5 m-0"></i>
                      </a>
                      <!--begin::Menu-->
                      <div
                        class="
                          menu
                          menu-sub
                          menu-sub-dropdown
                          menu-column
                          menu-rounded
                          menu-gray-600
                          menu-state-bg-light-primary
                          fw-semibold
                          fs-7
                          w-125px
                          py-4
                        "
                        data-kt-menu="true"
                      >
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="/metronic8/demo1/apps/user-management/users/view.html"
                            class="menu-link px-3"
                          >
                            Görüntüle
                          </a>
                        </div>
                        <!--end::Menu item-->

                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-kt-roles-table-filter="delete_row"
                          >
                            Kaydı Sil
                          </a>
                        </div>
                        <!--end::Menu item-->
                      </div>
                      <!--end::Menu-->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                        >
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-danger
                              text-danger
                            "
                          >
                            M
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <div class="d-flex flex-column">
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Melodi Acar</a
                        >
                        <span>melody@gmail.com</span>
                      </div>
                      <!--begin::User details-->
                    </td>
                    <td>
                     Hybrid Çalışacak Frontend Developer
                    </td>
                    <td data-order="2024-04-15T20:43:00+03:00">
                      15 Nisan 2024, 18:43
                    </td>
                    <td>
                      <span
                          class="badge badge-light-danger"
                          >{{ $t("common.ended") }}</span
                        >
                    </td>
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        İşlemler
                        <i class="ki-duotone ki-down fs-5 m-0"></i>
                      </a>
                      <!--begin::Menu-->
                      <div
                        class="
                          menu
                          menu-sub
                          menu-sub-dropdown
                          menu-column
                          menu-rounded
                          menu-gray-600
                          menu-state-bg-light-primary
                          fw-semibold
                          fs-7
                          w-125px
                          py-4
                        "
                        data-kt-menu="true"
                      >
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="/metronic8/demo1/apps/user-management/users/view.html"
                            class="menu-link px-3"
                          >
                            Görüntüle
                          </a>
                        </div>
                        <!--end::Menu item-->

                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-kt-roles-table-filter="delete_row"
                          >
                            Kaydı Sil
                          </a>
                        </div>
                        <!--end::Menu item-->
                      </div>
                      <!--end::Menu-->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                      <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                        >
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-primary
                              text-primary
                            "
                          >
                            B
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <div class="d-flex flex-column">
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Beytullah Perşembe</a
                        >
                        <span>beytu@kt.com</span>
                      </div>
                      <!--begin::User details-->
                    </td>
                    <td>
                     Hybrid Çalışacak Frontend Developer
                    </td>
                    <td data-order="2024-04-15T11:30:00+03:00">
                      15 Nisan 2024, 11:30 
                    </td>
                    <td>
                      <span
                      class="badge badge-light-success"
                          >{{ $t("common.continues") }}</span
                        >
                    </td>
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        İşlemler
                        <i class="ki-duotone ki-down fs-5 m-0"></i>
                      </a>
                      <!--begin::Menu-->
                      <div
                        class="
                          menu
                          menu-sub
                          menu-sub-dropdown
                          menu-column
                          menu-rounded
                          menu-gray-600
                          menu-state-bg-light-primary
                          fw-semibold
                          fs-7
                          w-125px
                          py-4
                        "
                        data-kt-menu="true"
                      >
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="/metronic8/demo1/apps/user-management/users/view.html"
                            class="menu-link px-3"
                          >
                            Görüntüle
                          </a>
                        </div>
                        <!--end::Menu item-->

                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-kt-roles-table-filter="delete_row"
                          >
                            Kaydı Sil
                          </a>
                        </div>
                        <!--end::Menu item-->
                      </div>
                      <!--end::Menu-->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                                          <!--begin:: Avatar -->
                                          <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                        >
                          <div
                            class="
                              symbol-label
                              fs-3
                              bg-light-primary
                              text-primary
                            "
                          >
                            S
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->

                      <!--begin::User details-->
                      <div class="d-flex flex-column">
                        <a
                          href="/metronic8/demo1/apps/user-management/users/view.html"
                          class="text-gray-800 text-hover-primary mb-1"
                          >Sinan Kılıç</a
                        >
                        <span>sinan@gmail.com</span>
                      </div>
                      <!--begin::User details-->
                    </td>
                    <td>
                     Ofisten Çalışacak Tecrübeli Frontend Developer
                    </td>
                    <td data-order="2024-06-24T17:20:00+03:00">
                      24 Mart 2024, 15:20
                    </td>
                    <td>
                      <span
                      class="badge badge-light-success"
                          >{{ $t("common.continues") }}</span
                        >
                    </td>
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        İşlemler
                        <i class="ki-duotone ki-down fs-5 m-0"></i>
                      </a>
                      <!--begin::Menu-->
                      <div
                        class="
                          menu
                          menu-sub
                          menu-sub-dropdown
                          menu-column
                          menu-rounded
                          menu-gray-600
                          menu-state-bg-light-primary
                          fw-semibold
                          fs-7
                          w-125px
                          py-4
                        "
                        data-kt-menu="true"
                      >
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="/metronic8/demo1/apps/user-management/users/view.html"
                            class="menu-link px-3"
                          >
                            Görüntüle
                          </a>
                        </div>
                        <!--end::Menu item-->

                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-kt-roles-table-filter="delete_row"
                          >
                            Kaydı Sil
                          </a>
                        </div>
                        <!--end::Menu item-->
                      </div>
                      <!--end::Menu-->
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
            <div id="" class="row">
              <div
                id=""
                class="
                  col-sm-12 col-md-5
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-start
                  dt-toolbar
                "
              ></div>
              <div
                id=""
                class="
                  col-sm-12 col-md-7
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-end
                "
              >
                <div class="dt-paging paging_simple_numbers">
                  <nav aria-label="pagination">
                    <ul class="pagination">
                      <li class="dt-paging-button page-item disabled">
                        <button
                          class="page-link previous"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-disabled="true"
                          aria-label="Previous"
                          data-dt-idx="previous"
                          tabindex="-1"
                        >
                          <i class="previous"></i>
                        </button>
                      </li>
                      <li class="dt-paging-button page-item active">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-current="page"
                          data-dt-idx="0"
                        >
                          1
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          data-dt-idx="1"
                        >
                          2
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          data-dt-idx="2"
                        >
                          3
                        </button>
                      </li>
                      <li class="dt-paging-button page-item">
                        <button
                          class="page-link next"
                          role="link"
                          type="button"
                          aria-controls="kt_roles_view_table"
                          aria-label="Next"
                          data-dt-idx="next"
                        >
                          <i class="next"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "NewInterviewDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const positionDesc = ref(""); 
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const interviewController =
      store.state.ControllersModule.interviewController;
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getInterviewList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interview: {},
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              // totalItems.value = jobPosting.pagination.total;
              // currentPage.value = jobPosting.pagination.page;
              // pageCount.value = jobPosting.pagination.pageCount;
              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteInterview = (interviewId) => {
      const interviewDeleteModel: DeleteJobPostingModel = {
        id: interviewId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_INTERVIEW),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewController
            .deleteJobPosting(interviewDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: "İşlem başarılı!",
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_INTERVIEW),
                  icon: "success",
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPostingDate = () => {
      const jobPostingUpdateModel: UpdateJobPostingModel = {};
      jobPostingUpdateModel.id = selectedInterviewId.value;
      jobPostingUpdateModel.endDate = new Date(newEndDate.value);

      interviewController
        .updateJobPosting(jobPostingUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: "İşlem başarılı!",
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
            }).finally(() => {
              hideModal(changeDateRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePollToClosed = async (id?: number) => {
      // const jobPostingUpdateModel: UpdatePollModel = {};
      // jobPostingUpdateModel.id = id;
      // jobPostingUpdateModel.completed = true;
      // Swal.fire({
      //   title: "Emin misiniz?",
      //   text: "Bu anketi sonlandırmak ister misiniz?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: "Tamam",
      //   cancelButtonText: "İptal",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //     cancelButton: "btn fw-bold btn-light-danger",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     interviewController
      //   .updatePoll(jobPostingUpdateModel)
      //   .then((response) => {
      //     if (response.isSuccess) {
      //       isChanged.value = true;
      //       Swal.fire({
      //         title: "İşlem başarılı!",
      //         text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
      //         icon: "success",
      //       })
      //     } else {
      //       swalNotification.error(
      //         response.error.cause.cause +
      //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
      //           response.error.code +
      //           response.error.cause.code,
      //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //       );
      //     }
      //   })
      //   .catch((e) => {
      //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
      //   });
      //   }
      // });
    };

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getInterviewList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewList(1);
    });

    return {
      url,
      jobPostingList,
      deleteInterview,
      form,
      newEndDate,
      updateJobPostingDate,
      updatePollToClosed,
      changeDateRef,
      selectInterview,
      isLoading,
      positionDesc
    };
  },
});
</script>
